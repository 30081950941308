import { gql } from 'graphql-request'
import { useCallback, useState } from 'react'
import useSWR from 'swr'
import { graphqlBunnyMining } from 'hooks/graphQl'

const QUERY = gql`
  query MyQuery($address: String, $first: Int, $skip: Int) {
    hatchBones_collection(
      first: $first
      skip: $skip
      orderBy: userAddress
      where: { blockTimestamp_gt: 1720630800, sponsor: $address }
    ) {
      userAddress
      amount
    }
  }
`

export const getData = async (address: string): Promise<{ result?: any; error: boolean }> => {
  try {
    const first = 1000
    let skip = 0
    let data: any | undefined

    while (true) {
      const dataRs = await graphqlBunnyMining.request(QUERY, { address, first: Number(first), skip: Number(skip) })

      if (!data) {
        data = {
          hatchBonesList: [],
        }
      }

      if (!dataRs || !dataRs.hatchBones_collection || dataRs.hatchBones_collection.length === 0) {
        break
      }

      data.hatchBonesList.push(...dataRs.hatchBones_collection)

      skip += 1000
    }

    return {
      result: data,
      error: false,
    }
  } catch (error) {
    console.error('getBuyBones', error)
    return { error: true }
  }
}

export const useGetHatchBonesCollection = (address: string) => {
  const [loading, setLoading] = useState(false)

  const fetchData = useCallback(async () => {
    if (!address) return

    setLoading(true)
    try {
      const { result } = await getData(address)
      setLoading(false)

      if (result?.hatchBonesList) {
        const formatData: any[] = []

        for (let i = 0; i < result?.hatchBonesList?.length; i++) {
          const current = result?.hatchBonesList?.[i]
          if (formatData?.find((x) => x?.address === current?.userAddress)) continue

          formatData.push({
            address: current?.userAddress,
            totalAmount: result?.hatchBonesList?.reduce((sum, cur) => {
              if (cur?.userAddress === current?.userAddress) return (sum += +cur?.amount)
              return sum
            }, 0),
          })
        }

        return formatData
      }
    } catch (error) {
      setLoading(false)
      console.log('🚀 ~ fetchData ~ useGetHatchBonesCollection:', error)
    }
  }, [address])

  const { data, mutate } = useSWR(
    ['getHatchBonesCollection'],
    () => {
      return fetchData()
    },
    {
      revalidateOnFocus: false,
    },
  )

  return { data, refresh: mutate, loading }
}
