/* eslint-disable react/button-has-type */
import { useWeb3React } from '@web3-react/core'
import { Col, Empty, Row } from 'antd'
import { useTranslation } from 'contexts/Localization'
import { formatCode } from 'helpers/CommonHelper'
import { useGetHatchBonesCollection } from 'hooks/bunnyMining/useGetHatchBonesCollection'
import { useGetUserAffiliate } from 'hooks/useGetUserAffiliate'
import useToast from 'hooks/useToast'
import * as qs from 'query-string'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setModalConnect } from 'state/modal/actions'
import styled, { css } from 'styled-components'
import { getBscScanLink, getRootUrl, toLocaleString } from 'utils'
import ShareModal from './ShareModal'

const Wrapper = styled.div`
  padding: 48px 0;
  background-color: #ffff0b;

  ${({ theme }) => theme.mediaQueries.md} {
    padding-top: 12px;
  }
`

const blockStyles = css`
  border: 1px solid #cecece;
  border-radius: 8px;
  padding: 12px 24px;
  font-family: Clash Display;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  text-align: justify;
  background-color: #fff;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  color: #000;

  ${({ theme }) => theme.mediaQueries.lg} {
    flex-direction: row;
  }

  .bunny {
    width: 100%;
    max-width: 200px;

    ${({ theme }) => theme.mediaQueries.md} {
      max-width: 300px;
    }

    ${({ theme }) => theme.mediaQueries.lg} {
      margin-top: 100px;
    }
  }

  .bunny + div {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    ${({ theme }) => theme.mediaQueries.md} {
      gap: 2rem;
    }
  }

  .label {
    font-family: Death Star;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.01em;
    text-align: left;

    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 0.5rem;

    ${({ theme }) => theme.mediaQueries.md} {
      gap: 1rem;
      font-size: 42px;
    }

    ${({ theme }) => theme.mediaQueries.lg} {
      gap: 2rem;
      font-size: 52px;
    }

    img {
      width: 80px;

      ${({ theme }) => theme.mediaQueries.md} {
        width: 160px;
      }
    }
  }

  .label-subtitle {
    font-family: Clash Display;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.01em;
    text-align: left;

    ${({ theme }) => theme.mediaQueries.md} {
      font-size: 20px;
    }
  }

  .your-link-block {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-family: Clash Display;
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;

    input {
      outline: none;
      padding: 12px;
      font-size: 14px;
      background-color: #00dfff;
      border: 2px solid #001db2;
      border-radius: 8px;
      width: 100%;

      ${({ theme }) => theme.mediaQueries.md} {
        padding: 16px 24px;
        font-size: 18px;
      }
    }

    .buttons {
      display: flex;
      gap: 1rem;
      justify-content: center;
      flex-wrap: wrap;

      ${({ theme }) => theme.mediaQueries.md} {
        flex-wrap: nowrap;
      }

      button {
        display: flex;
        gap: 0.75rem;
        padding: 12px;
        border-radius: 100px;
        cursor: pointer;
        border: 1px solid #000;
        align-items: center;

        ${({ theme }) => theme.mediaQueries.md} {
          gap: 1rem;
          padding: 12px 24px;
        }

        &:first-child {
          background-color: #000;
          color: #fff;
        }
      }

      span {
        font-family: Clash Display;
        font-size: 14px;
        font-weight: 700;
        line-height: 1.5;
        text-align: left;

        ${({ theme }) => theme.mediaQueries.md} {
          font-size: 20px;
        }
      }
    }
  }

  .note-block {
    ${blockStyles}

    ${({ theme }) => theme.mediaQueries.md} {
      font-size: 18px;
    }

    button {
      width: 100%;
      font-family: Clash Display;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.5;
      outline: none;
      background-color: #ececec;
      padding: 12px 24px;
      border-radius: 8px;
      text-align: center;
      margin-top: 12px;

      ${({ theme }) => theme.mediaQueries.md} {
        font-size: 18px;
      }
    }
  }
`

const F1RefTable = styled.div`
  ${blockStyles}
  padding: 12px;
  height: 100%;
  text-align: center;

  .list {
    height: 182px;
    overflow-y: scroll;
  }
`

const ConnectWalletButton = ({
  toggleWallet,
  className,
  t,
}: {
  toggleWallet: () => void
  className?: string
  t?: any
}) => {
  return (
    <button
      onClick={toggleWallet}
      className={`flex items-center gap-3 bg-[#FFD100] py-3 px-5 rounded-lg ${className || ''}`}
    >
      <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M25.8332 9.33333H24.4998V8C24.4998 6.93913 24.0784 5.92172 23.3283 5.17157C22.5781 4.42143 21.5607 4 20.4998 4H7.1665C6.10564 4 5.08822 4.42143 4.33808 5.17157C3.58793 5.92172 3.1665 6.93913 3.1665 8V24C3.1665 25.0609 3.58793 26.0783 4.33808 26.8284C5.08822 27.5786 6.10564 28 7.1665 28H25.8332C26.894 28 27.9115 27.5786 28.6616 26.8284C29.4117 26.0783 29.8332 25.0609 29.8332 24V13.3333C29.8332 12.2725 29.4117 11.2551 28.6616 10.5049C27.9115 9.75476 26.894 9.33333 25.8332 9.33333ZM7.1665 6.66667H20.4998C20.8535 6.66667 21.1926 6.80714 21.4426 7.05719C21.6927 7.30724 21.8332 7.64638 21.8332 8V9.33333H7.1665C6.81288 9.33333 6.47374 9.19286 6.2237 8.94281C5.97365 8.69276 5.83317 8.35362 5.83317 8C5.83317 7.64638 5.97365 7.30724 6.2237 7.05719C6.47374 6.80714 6.81288 6.66667 7.1665 6.66667ZM27.1665 20H25.8332C25.4795 20 25.1404 19.8595 24.8904 19.6095C24.6403 19.3594 24.4998 19.0203 24.4998 18.6667C24.4998 18.313 24.6403 17.9739 24.8904 17.7239C25.1404 17.4738 25.4795 17.3333 25.8332 17.3333H27.1665V20ZM27.1665 14.6667H25.8332C24.7723 14.6667 23.7549 15.0881 23.0047 15.8382C22.2546 16.5884 21.8332 17.6058 21.8332 18.6667C21.8332 19.7275 22.2546 20.7449 23.0047 21.4951C23.7549 22.2452 24.7723 22.6667 25.8332 22.6667H27.1665V24C27.1665 24.3536 27.026 24.6928 26.776 24.9428C26.5259 25.1929 26.1868 25.3333 25.8332 25.3333H7.1665C6.81288 25.3333 6.47374 25.1929 6.2237 24.9428C5.97365 24.6928 5.83317 24.3536 5.83317 24V11.7733C6.26153 11.924 6.71242 12.0007 7.1665 12H25.8332C26.1868 12 26.5259 12.1405 26.776 12.3905C27.026 12.6406 27.1665 12.9797 27.1665 13.3333V14.6667Z"
          fill="black"
        />
      </svg>
      {t('Connect Wallet')}
    </button>
  )
}

const Referral = () => {
  const { t } = useTranslation()
  const { account } = useWeb3React()

  const dispatch = useDispatch()
  const { toastSuccess } = useToast()

  const { data: userAffiliate } = useGetUserAffiliate(account)
  const { data: hatchList, refresh } = useGetHatchBonesCollection(account)

  const [visibleModalShare, setVisibleModalShare] = useState(false)

  const params = qs.stringify({
    startapp: account,
  })

  const refLink = useMemo(
    () => `${getRootUrl()}${window.location.port ? `:${window.location.port}` : ''}?${params}`,
    [params],
  )

  /**
   * functions
   * ====================================================================
   */

  const toggleWallet = () => {
    dispatch(setModalConnect({ toggle: true }))
  }

  const handleCopyRefLink = useCallback(() => {
    toastSuccess('Copied to clipboard')
    navigator.clipboard.writeText(refLink)
  }, [refLink, toastSuccess])

  useEffect(() => {
    if (account) refresh()
  }, [account, refresh])

  /**
   * render view
   * ====================================================================
   */

  return (
    <Wrapper>
      <div className="container">
        <Content>
          <img src="/images/mining/bunny-mining.png" alt="bunny" className="bunny" />
          <div>
            <div className="label">
              <div>{t('Referral Program')}</div>
              <img src="/images/mining/message.png" alt="message" />
            </div>
            <div className="label-subtitle">
              🔥 {t('Earn 12% of the BNB used to hire miners by anyone who start mining using your link')}
            </div>
            <div className="your-link-block">
              <div>{t('YOUR LINK')}:</div>
              <div className="flex flex-col gap-4 relative">
                <input type="text" readOnly value={refLink} />
                <div className="buttons">
                  <button onClick={handleCopyRefLink}>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M21.3332 17.1993V22.7993C21.3332 27.466 19.4665 29.3327 14.7998 29.3327H9.19984C4.53317 29.3327 2.6665 27.466 2.6665 22.7993V17.1993C2.6665 12.5327 4.53317 10.666 9.19984 10.666H14.7998C19.4665 10.666 21.3332 12.5327 21.3332 17.1993Z"
                        fill="white"
                      />
                      <path
                        d="M22.8002 2.66602H17.2002C12.9614 2.66602 11.0435 4.21708 10.7267 7.98691C10.6801 8.54186 11.1352 8.99935 11.6921 8.99935H14.8002C20.4002 8.99935 23.0002 11.5993 23.0002 17.1993V20.3074C23.0002 20.8644 23.4577 21.3194 24.0126 21.2728C27.7824 20.956 29.3335 19.0382 29.3335 14.7993V9.19935C29.3335 4.53268 27.4668 2.66602 22.8002 2.66602Z"
                        fill="white"
                      />
                    </svg>
                    <span>{t('Copy RefLink')}</span>
                  </button>
                  <button onClick={() => setVisibleModalShare(true)}>
                    <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M19.3332 0.333984C17.124 0.333984 15.3332 2.12485 15.3332 4.33398C15.3332 4.68662 15.3788 5.02859 15.4645 5.35433C15.37 5.37385 15.2768 5.40748 15.1877 5.45608L11.5123 7.46082L6.83704 10.1324C6.78925 10.1597 6.74457 10.1904 6.70313 10.2239C6.10658 9.8703 5.41025 9.66732 4.6665 9.66732C2.45737 9.66732 0.666504 11.4582 0.666504 13.6673C0.666504 15.8765 2.45737 17.6673 4.6665 17.6673C5.83193 17.6673 6.88095 17.1689 7.61203 16.3736L11.5142 18.5415L15.4534 20.6901C15.3749 21.0029 15.3332 21.3302 15.3332 21.6673C15.3332 23.8765 17.124 25.6673 19.3332 25.6673C21.5423 25.6673 23.3332 23.8765 23.3332 21.6673C23.3332 19.4582 21.5423 17.6673 19.3332 17.6673C18.1806 17.6673 17.1419 18.1548 16.4119 18.9348L12.4821 16.7913L8.5549 14.6095C8.62785 14.3074 8.6665 13.9919 8.6665 13.6673C8.6665 12.9477 8.47649 12.2725 8.1439 11.6891L12.4874 9.20713L16.1454 7.21187C16.2348 7.16311 16.3137 7.10269 16.3815 7.03353C17.1128 7.83276 18.1645 8.33398 19.3332 8.33398C21.5423 8.33398 23.3332 6.54312 23.3332 4.33398C23.3332 2.12485 21.5423 0.333984 19.3332 0.333984Z"
                        fill="black"
                      />
                    </svg>
                    <span>{t('Share RefLink')}</span>
                  </button>
                </div>

                {!account && (
                  <div className="absolute w-full h-full backdrop-blur-sm scale-125 rounded-lg flex justify-center items-center">
                    <div className="p-3 bg-[#E4FFFF] rounded-xl">
                      <ConnectWalletButton toggleWallet={toggleWallet} t={t} />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <Row gutter={[16, 16]}>
              <Col span={24} xl={{ span: 12 }}>
                <F1RefTable>
                  <p className="text-[20px] font-bold text-center uppercase mb-3">{t('Ref List')}</p>

                  <div className="flex flex-col items-center gap-2 list">
                    {account ? (
                      userAffiliate?.data?.tree?.children?.length ? (
                        <>
                          <Row gutter={[10, 10]} className="w-full">
                            <Col span={10}>
                              <p className="text-base font-bold">Address</p>
                            </Col>

                            <Col span={14}>
                              <p className="text-base font-bold">Ref Bones</p>
                            </Col>
                          </Row>

                          {userAffiliate?.data?.tree?.children?.map((item) => {
                            const foundData = hatchList?.find((x) => x?.address === item?.address)

                            return (
                              <Row gutter={[10, 10]} key={item?.address} className="w-full">
                                <Col span={10}>
                                  <a href={getBscScanLink(item?.address, 'address')} target="_blank" rel="noreferrer">
                                    <span className="text-center">{formatCode(item?.address, 6, 6)}</span>
                                  </a>
                                </Col>

                                <Col span={14}>
                                  <p className="break-all">{foundData ? toLocaleString(foundData?.totalAmount) : 0}</p>
                                </Col>
                              </Row>
                            )
                          })}
                        </>
                      ) : (
                        <Empty />
                      )
                    ) : (
                      <ConnectWalletButton toggleWallet={toggleWallet} className="m-auto" t={t} />
                    )}
                  </div>
                </F1RefTable>
              </Col>
              <Col span={24} xl={{ span: 12 }}>
                <div className="note-block">
                  <div>
                    {t(
                      'You will receive 12% (BNB value) used to hire miners when people start mining through your referral link. Our contract address is set up with a one-level referral system and sends rewards directly to your wallet.',
                    )}
                  </div>
                  <button>{t('Send your referral link and Earn 12% Referral Rewards now!')}</button>
                </div>
              </Col>
            </Row>
          </div>
        </Content>
      </div>
      <ShareModal url={refLink} open={visibleModalShare} onCancel={() => setVisibleModalShare(false)} />
    </Wrapper>
  )
}

export default Referral
