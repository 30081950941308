import { CopyOutlined, FacebookFilled } from '@ant-design/icons'
import { Modal, ModalProps } from 'antd'
import useToast from 'hooks/useToast'
import dynamic from 'next/dynamic'
import styled from 'styled-components'

const FacebookShareButton = dynamic<any>(() => import('react-share').then((module) => module.FacebookShareButton), {
  ssr: false,
})
const TelegramShareButton = dynamic<any>(() => import('react-share').then((module) => module.TelegramShareButton), {
  ssr: false,
})
const TwitterShareButton = dynamic<any>(() => import('react-share').then((module) => module.TwitterShareButton), {
  ssr: false,
})
const RedditShareButton = dynamic<any>(() => import('react-share').then((module) => module.RedditShareButton), {
  ssr: false,
})
const TelegramIcon = dynamic<any>(() => import('react-share').then((module) => module.TelegramIcon), { ssr: false })
const FacebookIcon = dynamic<any>(() => import('react-share').then((module) => module.FacebookIcon), { ssr: false })
const TwitterIcon = dynamic<any>(() => import('react-share').then((module) => module.TwitterIcon), { ssr: false })
const RedditIcon = dynamic<any>(() => import('react-share').then((module) => module.RedditIcon), { ssr: false })

const ShareButton = styled.div`
  width: 50px;
  aspect-ratio: 1;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

interface ShareModalProps extends ModalProps {
  url: string
}

const ShareModal: React.FC<ShareModalProps> = ({ url, ...props }) => {
  const { toastSuccess } = useToast()

  /**
   * functions
   * ====================================================================
   */

  const handleCopyRefLink = () => {
    navigator.clipboard.writeText(url)
    toastSuccess('Referral link copied')
  }

  /**
   * render view
   * ====================================================================
   */

  return (
    <Modal {...props} footer={false}>
      <div className="flex flex-col gap-6">
        <h1 className="text-xl font-bold">Share reflink</h1>

        <div className="flex flex-col gap-4">
          <input
            type="text"
            readOnly
            value={url}
            className="outline-none px-6 py-4 bg-[#00dfff] border-2 border-[#001db2] rounded-lg w-full text-black font-medium text-base"
          />

          <div className="flex items-center gap-3">
            <FacebookShareButton url={url}>
              <FacebookIcon className="w-[50px]" />
            </FacebookShareButton>

            <TelegramShareButton url={url}>
              <TelegramIcon className="w-[50px]" />
            </TelegramShareButton>

            <TwitterShareButton url={url}>
              <TwitterIcon className="w-[50px]" />
            </TwitterShareButton>

            <RedditShareButton url={url}>
              <RedditIcon className="w-[50px]" />
            </RedditShareButton>

            <ShareButton onClick={handleCopyRefLink}>
              <CopyOutlined rev={undefined} className="text-xl text-black" />
            </ShareButton>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ShareModal
